<template>
  <b-card>
    <!-- form -->
      <b-row>
             <b-col cols="10">
        
    <h2 class="text-primary">{{ title }}</h2>
         </b-col>
             <b-col cols="2">
                 <b-badge
      variant="warning"
      class="badge-glow"
      v-if="isUpdated"
    >
      Not Saved
    </b-badge> 
             </b-col>
      </b-row>
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
             label=""
            label-for="bio-area"
          >

          <div v-html="informationData">
            </div>
           
          </b-form-group>
        </b-col>
        <!--/ bio -->
 
        <!-- phone -->

        <b-col cols="12" v-if="3>6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="isLoading?true:false"
         @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
             :disabled="isLoading?true:false"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { integer } from '@/@core/utils/validations/validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
       quillEditor,
       BBadge
  },
  directives: {
    Ripple,
  },
  props: {
      title:{
      type: String,
      required: true,
      default: () => '',
    },
      id:{
      type: integer,
      required: true,
      default: () => '',
    },
    informationData: {
     type: String,
      required: false,
      default: () => '',
     
    },
    fieldInformation: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
       snowOption: {
        theme: 'snow',
      },
      isLoading:false,
      isUpdated:false,
        
      localOptions: JSON.parse(JSON.stringify(this.informationData??"")),

      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {

      sendInformation() {
       
      //console.log("update course  is launching with id : "+this.id );
     
 this.isLoading=true;
      axios({
    method: 'put',
    url: `/lms/admin/course/updatecoursedetail/${this.id}`,
    data: {
        info:this.fieldInformation,
        content: this.localOptions
    }
}) .then((r) => { 
    
    this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Success`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                  },
                });
                this.isLoading=false;
                this.isUpdated=false;

      }
                
                )
    .catch((r) => { 
     this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    icon: "AlertOctagonIcon",
                    variant: "warning",
                  },
                });
                   this.isLoading=false;
    }
    );
 return ;
      
    
    },

    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData??""))
    },
     onEditorBlur(quill) {
    //    //console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
     //   //console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
   //     //console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
   //     //console.log('editor change!', quill, html, text)
   this.isUpdated=true;
        this.content = html
      }
  },
  computed: {
      editor() {
     //   return this.$refs.myQuillEditor.quill
      }
    },
    mounted() {

        var data= JSON.parse(JSON.stringify(this.informationData??""));

     //   //console.log(data);

   //   //console.log('this is current quill instance object', this.editor)
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
 
@import '@core/scss/vue/libs/quill.scss';

.ql-editor{
    min-height:400px;
}
</style>

